import './App.css';
import { Row, Col, Form, Button, Spinner } from 'react-bootstrap';
import {useState} from "react";
import axios from "axios";

function App() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [pendingLogin, setPendingLogin] = useState(false);

  const loginAction = (e) => {
    e.preventDefault();
    setPendingLogin(true);
    axios.post('https://gym-app.sergiu-dev.ro/user/login', {
      username: username,
      password: password
    }).then((response) => {
      console.log(response);
      setPendingLogin(false);
    })
  }

  return (
    <div className="App">
      <Row className="justify-content-md-center">
        <Col md={4} style={{marginTop: '30vh', backgroundColor: '#fff', borderRadius: 10}} >
          <Row>
            <Col md={6}>
              <div className={'mt-5 mb-5'}>
                <h5>Admin Login</h5>
              </div>
              <div style={{textAlign: 'left'}} className={'mb-5'}>
                <Form onSubmit={(e) => loginAction(e)}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control type="text" placeholder="Enter email" value={username} onChange={(input) => setUsername(input.target.value)} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password" value={password} onChange={(input) => setPassword(input.target.value)} />
                  </Form.Group>
                  <div style={{width: '100%', textAlign: 'right'}}>
                  <Button variant="success" type="submit">
                    {pendingLogin && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                    /> } Login into account
                  </Button>
                  </div>
                </Form>
              </div>
            </Col>
            <Col md={6} style={{backgroundImage: 'url(https://www.out-sport.eu/wp-content/uploads/2018/05/Schermata-2018-06-19-alle-11.07.32-800x563.png)', backgroundSize: 'cover', borderTopRightRadius: 10, borderBottomRightRadius: 10}}>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default App;
